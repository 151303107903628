var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[_c('div',{staticClass:"d-flex justify-space-between flex-wrap"},[_c('v-btn',{staticClass:"ma-2",attrs:{"dark":"","color":"secondary","outlined":""},on:{"click":function($event){return _vm.$router.back()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")]),_vm._v(" Back ")],1)],1)]),_c('v-card-title',[_vm._v(_vm._s(_vm.page.title || ""))]),_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.validForm),callback:function ($$v) {_vm.validForm=$$v},expression:"validForm"}},[_vm._l((_vm.types),function(item){return [(_vm.textFields.includes(item.type))?_c('div',{key:item[_vm.page.primary_key]},[_c('v-text-field',{attrs:{"prepend-icon":item.type === 'email' ? 'mdi-email-outline' : 'mdi-text',"type":item.type,"label":item.label,"rules":_vm.rule.set(item),"outlined":""},model:{value:(_vm.dynamic[item.name]),callback:function ($$v) {_vm.$set(_vm.dynamic, item.name, $$v)},expression:"dynamic[item.name]"}})],1):_vm._e(),(item.type === 'number')?_c('div',{key:item[_vm.page.primary_key]},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-numeric","type":item.type,"label":item.label,"rules":_vm.rule.set(item),"outlined":""},model:{value:(_vm.dynamic[item.name]),callback:function ($$v) {_vm.$set(_vm.dynamic, item.name, _vm._n($$v))},expression:"dynamic[item.name]"}})],1):_vm._e(),(item.type === 'password')?_c('div',{key:item[_vm.page.primary_key]},[_c('v-text-field',{attrs:{"prepend-icon":"mdi-lock-outline","label":item.label,"rules":_vm.rule.set(item),"type":_vm.dynamic[((item.name) + "_av2_pass_preview")]
                        ? 'text'
                        : 'password',"append-icon":_vm.dynamic[((item.name) + "_av2_pass_preview")]
                        ? 'mdi-eye'
                        : 'mdi-eye-off',"outlined":""},on:{"click:append":function($event){return _vm.toggleView(((item.name) + "_av2_pass_preview"))}},model:{value:(_vm.dynamic[item.name]),callback:function ($$v) {_vm.$set(_vm.dynamic, item.name, $$v)},expression:"dynamic[item.name]"}})],1):_vm._e(),(
                    _vm.selectFields.includes(item.type) &&
                      item.relation_type !== _vm.relationshipTypes.ONE_TO_MANY
                  )?_c('div',{key:item[_vm.page.primary_key]},[_c('v-select',{attrs:{"prepend-icon":item.type === 'select'
                        ? 'mdi-format-list-bulleted-square'
                        : 'mdi-format-list-checks',"item-text":"name","item-value":"id","items":item.list,"label":item.label,"rules":_vm.rule.set(item),"multiple":item.type === 'multi-select',"outlined":""},scopedSlots:_vm._u([(item.type === 'multi-select')?{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.toggleSelectAll(
                            item.list,
                            item.name || item.foreign_table
                          )}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.dynamic[item.name || item.foreign_table]
                                ? 'primary darken-2'
                                : ''}},[_vm._v(" "+_vm._s(_vm.iconSelectAll( item.list, item.name || item.foreign_table ))+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Select All ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}:null],null,true),model:{value:(_vm.dynamic[_vm.getItemKey(item)]),callback:function ($$v) {_vm.$set(_vm.dynamic, _vm.getItemKey(item), $$v)},expression:"dynamic[getItemKey(item)]"}})],1):_vm._e(),(item.type === 'textarea')?_c('div',{key:item[_vm.page.primary_key]},[_c('v-textarea',{attrs:{"prepend-icon":"mdi-text-subject","label":item.label,"rules":_vm.rule.set(item),"outlined":""},model:{value:(_vm.dynamic[item.name]),callback:function ($$v) {_vm.$set(_vm.dynamic, item.name, $$v)},expression:"dynamic[item.name]"}})],1):_vm._e(),(item.type === 'html')?_c('div',{key:item[_vm.page.primary_key],staticClass:"d-flex mb-5 pb-2"},[_c('v-icon',{staticClass:"pr-2 html-icon"},[_vm._v("mdi-text-subject")]),_c('div',{staticClass:"w-100"},[_c('v-label',{staticClass:"v-label theme--light"},[_vm._v(" "+_vm._s(item.label)+" ")]),_c('HtmlEditor',{attrs:{"className":_vm.submitted && item.required && !_vm.dynamic[item.name]
                          ? 'html-body-error w-100 mt-2'
                          : 'w-100 mt-2'},model:{value:(_vm.dynamic[item.name]),callback:function ($$v) {_vm.$set(_vm.dynamic, item.name, $$v)},expression:"dynamic[item.name]"}}),_c('v-slide-y-transition',[(
                          _vm.submitted && item.required && !_vm.dynamic[item.name]
                        )?_c('div',{staticClass:"html-body-error--detail red--text text--lighten-6 mx-3"},[_vm._v(" Required. ")]):_vm._e()])],1)],1):_vm._e(),(_vm.fileFields.includes(item.type))?_c('div',{key:item[_vm.page.primary_key]},[_c('v-file-input',{attrs:{"label":item.label,"rules":_vm.rule.set(item),"accept":_vm.accepts[item.type.toUpperCase()],"prepend-icon":_vm.fileInputIcon[item.type.toUpperCase()],"show-size":"","outlined":""},model:{value:(_vm.dynamic[item.name]),callback:function ($$v) {_vm.$set(_vm.dynamic, item.name, $$v)},expression:"dynamic[item.name]"}})],1):_vm._e(),(item.type === 'switch')?_c('div',{key:item[_vm.page.primary_key]},[_c('v-switch',{attrs:{"prepend-icon":"mdi-","label":item.label,"rules":_vm.rule.set(item),"outlined":""},model:{value:(_vm.dynamic[item.name]),callback:function ($$v) {_vm.$set(_vm.dynamic, item.name, $$v)},expression:"dynamic[item.name]"}})],1):_vm._e(),(item.type === 'checkbox')?_c('div',{key:item[_vm.page.primary_key]},[_c('v-checkbox',{attrs:{"prepend-icon":"mdi-","label":item.label,"rules":_vm.rule.set(item),"outlined":""},model:{value:(_vm.dynamic[item.name]),callback:function ($$v) {_vm.$set(_vm.dynamic, item.name, $$v)},expression:"dynamic[item.name]"}})],1):_vm._e(),(item.type === 'datetime')?_c('div',{key:item[_vm.page.primary_key]},[_c('v-datetime-picker',{attrs:{"label":item.label,"text-field-props":{
                      prependIcon: 'mdi-calendar',
                      outlined: true,
                      rules: _vm.rule.set(item)
                    },"date-picker-props":{ scrollable: true },"time-picker-props":{
                      scrollable: true,
                      format: '24hr',
                      useSeconds: true
                    },"time-format":"HH:mm:ss","clearText":"Cancel","prepend-icon":"mdi-calendar","outlined":""},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
                    var parent = ref.parent;
return [_c('v-btn',{attrs:{"text":""},nativeOn:{"click":function($event){return parent.clearHandler.apply(null, arguments)}}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":parent.okHandler}},[_vm._v("OK")])]}}],null,true),model:{value:(_vm.dynamic[item.name]),callback:function ($$v) {_vm.$set(_vm.dynamic, item.name, $$v)},expression:"dynamic[item.name]"}},[_c('template',{slot:"dateIcon"},[_c('v-icon',[_vm._v("mdi-calendar")])],1),_c('template',{slot:"timeIcon"},[_c('v-icon',[_vm._v("mdi-clock-time-four-outline")])],1)],2)],1):_vm._e(),(item.type === 'date')?_c('div',{key:item[_vm.page.primary_key]},[_c('v-dialog',{ref:((item.name) + "_av2_date_modal"),refInFor:true,attrs:{"width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":item.label,"prepend-icon":"mdi-calendar","outlined":"","readonly":""},model:{value:(_vm.dynamic[item.name]),callback:function ($$v) {_vm.$set(_vm.dynamic, item.name, $$v)},expression:"dynamic[item.name]"}},on))]}}],null,true),model:{value:(_vm.dynamic[((item.name) + "_av2_date_modal")]),callback:function ($$v) {_vm.$set(_vm.dynamic, ((item.name) + "_av2_date_modal"), $$v)},expression:"dynamic[`${item.name}_av2_date_modal`]"}},[_c('v-date-picker',{attrs:{"scrollable":""},on:{"input":function($event){return _vm.$set(_vm.dynamic, ((item.name) + "_av2_date_modal"), false)}},model:{value:(_vm.dynamic[item.name]),callback:function ($$v) {_vm.$set(_vm.dynamic, item.name, $$v)},expression:"dynamic[item.name]"}})],1)],1):_vm._e(),(item.type === 'gallery')?_c('div',{key:item[_vm.page.primary_key]},[_c('Gallery',{attrs:{"label":item.label,"src-key":item.foreign_table_column,"rules":_vm.rule.set(item)},model:{value:(_vm.dynamic[item.foreign_table]),callback:function ($$v) {_vm.$set(_vm.dynamic, item.foreign_table, $$v)},expression:"dynamic[item.foreign_table]"}})],1):_vm._e(),(item.type === 'time')?_c('div',{key:item[_vm.page.primary_key]},[_c('v-dialog',{ref:((item.name) + "_av2_time_modal"),refInFor:true,attrs:{"return-value":_vm.dynamic[item.name],"width":"312px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.dynamic, item.name, $event)},"update:return-value":function($event){return _vm.$set(_vm.dynamic, item.name, $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":item.label,"rules":_vm.rule.set(item),"prepend-icon":"mdi-clock-time-four-outline","readonly":"","outlined":""},model:{value:(_vm.dynamic[item.name]),callback:function ($$v) {_vm.$set(_vm.dynamic, item.name, $$v)},expression:"dynamic[item.name]"}},on))]}}],null,true),model:{value:(_vm.dynamic[((item.name) + "_av2_time_modal")]),callback:function ($$v) {_vm.$set(_vm.dynamic, ((item.name) + "_av2_time_modal"), $$v)},expression:"dynamic[`${item.name}_av2_time_modal`]"}},[_c('v-time-picker',{attrs:{"format":"24hr","scrollable":"","use-seconds":""},model:{value:(_vm.dynamic[item.name]),callback:function ($$v) {_vm.$set(_vm.dynamic, item.name, $$v)},expression:"dynamic[item.name]"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$set(_vm.dynamic, ((item.name) + "_av2_time_modal"), false)}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.$refs[((item.name) + "_av2_time_modal")][0].save(
                            _vm.dynamic[item.name]
                          )}}},[_vm._v(" OK ")])],1)],1)],1):_vm._e()]}),_c('v-btn',{staticClass:"float-right",attrs:{"color":"primary","loading":_vm.getModelsLoading,"disabled":_vm.getModelsLoading},on:{"click":_vm.save}},[_vm._v(" "+_vm._s(_vm.isCreate ? "Create " + _vm.model : "Save changes")+" ")])],2)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }