import { get } from "lodash";
import { FILE_ACCEPTS, FILE_FIELD_TYPES } from "./constants";

const required = value => !!value || value === 0 || "Required.";

const requiredMultiSelect = value => !!_.size(value) || "Required.";

const email = value => {
  const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  if (!value) {
    return true;
  }

  return pattern.test(value) || "Invalid email format.";
};

const fileSize = value =>
  !value || value.size < 2000000 || "File size should be less than 2 MB.";

const imageFileType = value =>
  !value ||
  FILE_ACCEPTS.IMAGE.split(",").includes(get(value, "type")) ||
  "File type should be an image.";

const pdfFileType = value =>
  !value ||
  FILE_ACCEPTS.PDF === get(value, "type") ||
  "File type should be a pdf.";

const set = field => {
  const type = get(field, "type");
  const rules = [];

  if (get(field, "required")) {
    type === "multi-select"
      ? rules.push(requiredMultiSelect)
      : rules.push(required);
  }

  if (type === "email") {
    rules.push(email);
  }

  if (FILE_FIELD_TYPES.includes(type)) {
    rules.push(fileSize);

    switch (type) {
      case "image":
        rules.push(imageFileType);
        break;
      case "pdf":
        rules.push(pdfFileType);
        break;
    }
  }

  return rules;
};

export default {
  set,
  required,
  email
};
