<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-card-title>
          <div class="d-flex justify-space-between flex-wrap">
            <v-btn
              class="ma-2"
              dark
              color="secondary"
              outlined
              @click="$router.back()"
            >
              <v-icon>mdi-arrow-left</v-icon>
              Back
            </v-btn>
          </div>
        </v-card-title>
        <v-card-title>{{ page.title || "" }}</v-card-title>
        <v-card-title>
          <v-row>
            <v-col cols="12">
              <v-form ref="form" v-model="validForm" lazy-validation>
                <template v-for="item in types">
                  <div
                    :key="item[page.primary_key]"
                    v-if="textFields.includes(item.type)"
                  >
                    <v-text-field
                      v-model="dynamic[item.name]"
                      :prepend-icon="
                        item.type === 'email' ? 'mdi-email-outline' : 'mdi-text'
                      "
                      :type="item.type"
                      :label="item.label"
                      :rules="rule.set(item)"
                      outlined
                    />
                  </div>

                  <div
                    :key="item[page.primary_key]"
                    v-if="item.type === 'number'"
                  >
                    <v-text-field
                      v-model.number="dynamic[item.name]"
                      prepend-icon="mdi-numeric"
                      :type="item.type"
                      :label="item.label"
                      :rules="rule.set(item)"
                      outlined
                    />
                  </div>

                  <div
                    :key="item[page.primary_key]"
                    v-if="item.type === 'password'"
                  >
                    <v-text-field
                      v-model="dynamic[item.name]"
                      prepend-icon="mdi-lock-outline"
                      :label="item.label"
                      :rules="rule.set(item)"
                      :type="
                        dynamic[`${item.name}_av2_pass_preview`]
                          ? 'text'
                          : 'password'
                      "
                      :append-icon="
                        dynamic[`${item.name}_av2_pass_preview`]
                          ? 'mdi-eye'
                          : 'mdi-eye-off'
                      "
                      @click:append="
                        toggleView(`${item.name}_av2_pass_preview`)
                      "
                      outlined
                    />
                  </div>

                  <div
                    :key="item[page.primary_key]"
                    v-if="
                      selectFields.includes(item.type) &&
                        item.relation_type !== relationshipTypes.ONE_TO_MANY
                    "
                  >
                    <v-select
                      v-model="dynamic[getItemKey(item)]"
                      :prepend-icon="
                        item.type === 'select'
                          ? 'mdi-format-list-bulleted-square'
                          : 'mdi-format-list-checks'
                      "
                      item-text="name"
                      item-value="id"
                      :items="item.list"
                      :label="item.label"
                      :rules="rule.set(item)"
                      :multiple="item.type === 'multi-select'"
                      outlined
                    >
                      <template
                        v-if="item.type === 'multi-select'"
                        v-slot:prepend-item
                      >
                        <v-list-item
                          ripple
                          @click="
                            toggleSelectAll(
                              item.list,
                              item.name || item.foreign_table
                            )
                          "
                        >
                          <v-list-item-action>
                            <v-icon
                              :color="
                                dynamic[item.name || item.foreign_table]
                                  ? 'primary darken-2'
                                  : ''
                              "
                            >
                              {{
                                iconSelectAll(
                                  item.list,
                                  item.name || item.foreign_table
                                )
                              }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>
                              Select All
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>
                    </v-select>
                  </div>

                  <div
                    :key="item[page.primary_key]"
                    v-if="item.type === 'textarea'"
                  >
                    <v-textarea
                      v-model="dynamic[item.name]"
                      prepend-icon="mdi-text-subject"
                      :label="item.label"
                      :rules="rule.set(item)"
                      outlined
                    />
                  </div>

                  <div
                    class="d-flex mb-5 pb-2"
                    :key="item[page.primary_key]"
                    v-if="item.type === 'html'"
                  >
                    <v-icon class="pr-2 html-icon">mdi-text-subject</v-icon>
                    <div class="w-100">
                      <v-label class="v-label theme--light">
                        {{ item.label }}
                      </v-label>
                      <HtmlEditor
                        v-model="dynamic[item.name]"
                        :className="
                          submitted && item.required && !dynamic[item.name]
                            ? 'html-body-error w-100 mt-2'
                            : 'w-100 mt-2'
                        "
                      ></HtmlEditor>
                      <v-slide-y-transition>
                        <div
                          v-if="
                            submitted && item.required && !dynamic[item.name]
                          "
                          class="html-body-error--detail red--text text--lighten-6 mx-3"
                        >
                          Required.
                        </div>
                      </v-slide-y-transition>
                    </div>
                  </div>

                  <div
                    :key="item[page.primary_key]"
                    v-if="fileFields.includes(item.type)"
                  >
                    <v-file-input
                      v-model="dynamic[item.name]"
                      :label="item.label"
                      :rules="rule.set(item)"
                      :accept="accepts[item.type.toUpperCase()]"
                      :prepend-icon="fileInputIcon[item.type.toUpperCase()]"
                      show-size
                      outlined
                    ></v-file-input>
                  </div>

                  <div
                    :key="item[page.primary_key]"
                    v-if="item.type === 'switch'"
                  >
                    <v-switch
                      v-model="dynamic[item.name]"
                      prepend-icon="mdi-"
                      :label="item.label"
                      :rules="rule.set(item)"
                      outlined
                    />
                  </div>

                  <div
                    :key="item[page.primary_key]"
                    v-if="item.type === 'checkbox'"
                  >
                    <v-checkbox
                      v-model="dynamic[item.name]"
                      prepend-icon="mdi-"
                      :label="item.label"
                      :rules="rule.set(item)"
                      outlined
                    />
                  </div>

                  <div
                    :key="item[page.primary_key]"
                    v-if="item.type === 'datetime'"
                  >
                    <v-datetime-picker
                      v-model="dynamic[item.name]"
                      :label="item.label"
                      :text-field-props="{
                        prependIcon: 'mdi-calendar',
                        outlined: true,
                        rules: rule.set(item)
                      }"
                      :date-picker-props="{ scrollable: true }"
                      :time-picker-props="{
                        scrollable: true,
                        format: '24hr',
                        useSeconds: true
                      }"
                      time-format="HH:mm:ss"
                      clearText="Cancel"
                      prepend-icon="mdi-calendar"
                      outlined
                    >
                      <template slot="dateIcon">
                        <v-icon>mdi-calendar</v-icon>
                      </template>
                      <template slot="timeIcon">
                        <v-icon>mdi-clock-time-four-outline</v-icon>
                      </template>
                      <template slot="actions" slot-scope="{ parent }">
                        <v-btn text @click.native="parent.clearHandler"
                          >Cancel</v-btn
                        >
                        <v-btn text color="primary" @click="parent.okHandler"
                          >OK</v-btn
                        >
                      </template>
                    </v-datetime-picker>
                  </div>

                  <div
                    :key="item[page.primary_key]"
                    v-if="item.type === 'date'"
                  >
                    <v-dialog
                      :ref="`${item.name}_av2_date_modal`"
                      v-model="dynamic[`${item.name}_av2_date_modal`]"
                      width="290px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dynamic[item.name]"
                          :label="item.label"
                          prepend-icon="mdi-calendar"
                          v-on="on"
                          outlined
                          readonly
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        v-model="dynamic[item.name]"
                        @input="
                          $set(dynamic, `${item.name}_av2_date_modal`, false)
                        "
                        scrollable
                      >
                      </v-date-picker>
                    </v-dialog>
                  </div>

                  <div
                    :key="item[page.primary_key]"
                    v-if="item.type === 'gallery'"
                  >
                    <Gallery
                      v-model="dynamic[item.foreign_table]"
                      :label="item.label"
                      :src-key="item.foreign_table_column"
                      :rules="rule.set(item)"
                    ></Gallery>
                  </div>
                  <div
                    :key="item[page.primary_key]"
                    v-if="item.type === 'time'"
                  >
                    <v-dialog
                      :ref="`${item.name}_av2_time_modal`"
                      :return-value.sync="dynamic[item.name]"
                      v-model="dynamic[`${item.name}_av2_time_modal`]"
                      width="312px"
                    >
                      <template v-slot:activator="{ on }">
                        <v-text-field
                          v-model="dynamic[item.name]"
                          :label="item.label"
                          :rules="rule.set(item)"
                          prepend-icon="mdi-clock-time-four-outline"
                          v-on="on"
                          readonly
                          outlined
                        ></v-text-field>
                      </template>
                      <v-time-picker
                        v-model="dynamic[item.name]"
                        format="24hr"
                        scrollable
                        use-seconds
                      >
                        <v-spacer></v-spacer>
                        <v-btn
                          text
                          @click="
                            $set(dynamic, `${item.name}_av2_time_modal`, false)
                          "
                        >
                          Cancel
                        </v-btn>
                        <v-btn
                          text
                          color="primary"
                          @click="
                            $refs[`${item.name}_av2_time_modal`][0].save(
                              dynamic[item.name]
                            )
                          "
                        >
                          OK
                        </v-btn>
                      </v-time-picker>
                    </v-dialog>
                  </div>
                </template>
                <v-btn
                  color="primary"
                  class="float-right"
                  :loading="getModelsLoading"
                  :disabled="getModelsLoading"
                  @click="save"
                >
                  {{ isCreate ? "Create " + model : "Save changes" }}
                </v-btn>
              </v-form>
            </v-col>
          </v-row>
        </v-card-title>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import rules from "@/shared/rules";
import _ from "lodash";
import moment from "moment";
import HtmlEditor from "@/components/HtmlEditor";
import {
  FILE_ACCEPTS,
  FILE_INPUT_ICON,
  FILE_FIELD_TYPES,
  RELATION_TYPES
} from "@/shared/constants";
import Gallery from "@/components/Gallery";

export default {
  metaInfo() {
    return {
      title:
        _.capitalize(_.upperFirst(_.replace(this.model, "-", " "))) || "Models"
    };
  },
  components: { HtmlEditor, Gallery },
  data() {
    return {
      relationshipTypes: RELATION_TYPES,
      submitted: false,
      textFields: ["text", "email"],
      selectFields: ["select", "multi-select"],
      fileFields: FILE_FIELD_TYPES,
      id: _.get(this.$route, "params.id"),
      page: { title: null },
      item: null,
      validForm: false,
      rule: rules,
      accepts: FILE_ACCEPTS,
      fileInputIcon: FILE_INPUT_ICON,
      types: [],
      dynamic: {},
      dateModals: [],
      timeModals: [],
      datetimeAttrs: [],
      passwordTogglers: [],
      toggleAttrs: [],
      fileAttrs: [],
      foreignKeys: []
    };
  },
  mounted() {
    if (this.isCreate) {
      this.$store.commit("setEditModel", null);
    }

    this.page = this.$store.getters.getPage({ model: this.model });
    this.types = this.$store.getters.getPageFields(this.model);
    this.setDynamicFields();
  },
  methods: {
    ...mapActions(["createModel", "updateModel"]),
    getItemKey(item) {
      if (item.is_foreign && item.relation_type !== RELATION_TYPES.ONE_TO_ONE) {
        return item.foreign_table;
      }

      return item.name;
    },
    iconSelectAll(options, key) {
      const optionIds = _.map(options, option => option.id);
      const selectedOptionIds = _.split(this.dynamic[key], ",");
      if (_.size(selectedOptionIds) === _.size(optionIds))
        return "mdi-close-box";
      if (_.size(this.dynamic[key])) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
    toggleSelectAll(options, key) {
      const optionIds = _.map(options, option => option.id);
      const selectedOptionIds = _.split(this.dynamic[key], ",");
      if (_.size(selectedOptionIds) === _.size(optionIds)) {
        this.dynamic[key] = [];
      } else {
        this.dynamic[key] = optionIds;
      }
    },
    setDynamicForeignFields(field) {
      switch (field.relation_type) {
        case RELATION_TYPES.ONE_TO_MANY:
          break;
        case RELATION_TYPES.MANY_TO_MANY:
        case RELATION_TYPES.ONE_TO_MANY_IMAGES:
        case RELATION_TYPES.MANY_TO_MANY_COMMA:
          const foreignTable = _.get(field, "foreign_table");
          this.$set(
            this.dynamic,
            foreignTable,
            _.get(this.getModelEdit, foreignTable)
          );
          break;
        default:
          this.$set(
            this.dynamic,
            field.name,
            _.get(this.getModelEdit, field.name)
          );
          break;
      }
    },
    setDynamicFields() {
      _.each(this.types, field => {
        const type = _.get(field, "type");
        const name = _.get(field, "name");

        if (field.is_foreign) {
          return this.setDynamicForeignFields(field);
        }

        switch (type) {
          case "datetime":
            this.$set(this.dynamic, name, _.get(this.getModelEdit, name));
            this.datetimeAttrs.push(name);
            break;
          case "date":
            this.$set(
              this.dynamic,
              name,
              _.get(this.getModelEdit, name)
                ? new Date(_.get(this.getModelEdit, name))
                    .toISOString()
                    .substr(0, 10)
                : null
            );
            this.$set(this.dynamic, `${name}_av2_date_modal`, false);
            this.dateModals.push(`${name}_av2_date_modal`);
            break;
          case "time":
            this.$set(this.dynamic, `${name}_av2_time_modal`, false);
            this.timeModals.push(`${name}_av2_time_modal`);
          case "password":
            this.$set(field, "required", this.isCreate);
            this.$set(this.dynamic, `${name}_av2_pass_preview`, false);
            this.passwordTogglers.push(`${name}_av2_pass_preview`);
            break;
          case "file":
          case "image":
          case "pdf":
            this.$set(this.dynamic, `${name}`, null);
            this.$set(
              this.dynamic,
              `${name}_av2_file_preview`,
              _.get(this.getModelEdit, name) || ""
            );
            this.fileAttrs.push(name);
            break;
          case "switch":
          case "checkbox":
            this.$set(this.dynamic, name, _.get(this.getModelEdit, name));
            this.toggleAttrs.push(name);
            break;
          default:
            this.$set(this.dynamic, name, _.get(this.getModelEdit, name) || "");
            break;
        }
      });
    },
    toggleView(key) {
      this.dynamic[key] = !this.dynamic[key];
      this.$forceUpdate();
    },
    removeImagePreview(key) {
      this.dynamic[key] = "";
      this.$forceUpdate();
    },
    save() {
      this.submitted = true;

      if (!this.$refs.form.validate()) {
        this.$store.dispatch("showSnackbar", "One or more fields are invalid.");
        return;
      }

      const payload = new FormData();

      for (let [key, value] of Object.entries(this.dynamic)) {
        if (
          (key === "password" && !value) ||
          this.passwordTogglers.includes(key) ||
          this.dateModals.includes(key) ||
          this.timeModals.includes(key)
        ) {
          continue;
        }

        if (
          _.find(this.types, {
            foreign_table: key,
            relation_type: RELATION_TYPES.ONE_TO_MANY_IMAGES
          })
        ) {
          _.each(value.uploads, (upload, dataKey) => {
            payload.append(`${key}[uploads][${dataKey}]`, upload);
          });
          _.each(value.remove, (remove, dataKey) => {
            payload.append(`${key}[remove][${dataKey}]`, remove);
          });
          continue;
        }

        payload.append(
          key,
          this.datetimeAttrs.includes(key)
            ? moment(value).format("YYYY-MM-DD HH:mm:ss")
            : this.toggleAttrs.includes(key)
            ? Number(!!value)
            : value === null || value === undefined
            ? ""
            : value
        );
      }

      if (_.size(this.fileAttrs)) {
        payload.append("av2_files_attr", JSON.stringify(this.fileAttrs));

        if (this.isCreate) {
          _.omit(this.fileAttrs, [""]);
        }
      }

      this.isCreate
        ? this.createModel({ model: this.model, payload })
        : this.updateModel({ model: this.model, id: this.id, payload });
    }
  },
  computed: {
    ...mapGetters(["getModelEdit", "getModelsLoading"]),
    isCreate() {
      return _.get(this.$route, "meta.kind") === "create";
    },
    model() {
      return _.get(this.$route, "params.model");
    }
  }
};
</script>
<style lang="scss" scoped></style>
